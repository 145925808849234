import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";
import { isNullOrUndefined } from "util";

export function getExpenseRunningNo(storeID) {
  return (dispatch) => {
    return database
      .collection("TransactionRunningNo")
      .doc("expense" + storeID)
      .get()
      .then((doc) => {
        let runningNo = 1;
        if (doc.exists) {
          runningNo = Number(doc.data().RunningNo) + 1;
        }
        return database
          .collection("TransactionRunningNo")
          .doc("expense" + storeID)
          .set({
            StoreID: storeID,
            RunningNo: runningNo,
          })
          .then(() => {
            return runningNo;
          });
      });
  };
}

export function getExpenses(
  RegistrationID,
  StoreID,
  from,
  size,
  option,
  searchParty,
  searchExpenseDateFrom,
  searchExpenseDateTo,
  searchExpenseCategory,
  searchExpenseNumber,
  searchReferenceNumber
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { term: { "RegistrationID.keyword": RegistrationID } },
          { term: { "StoreID.keyword": StoreID } },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };
  if (searchExpenseDateTo !== "" && searchExpenseDateFrom !== "") {
    searchParameters.query.bool.must.push({
      range: {
        ExpenseDate: {
          lte: searchExpenseDateTo,
          gte: searchExpenseDateFrom,
          format: "yyyy-MM-dd",
        },
      },
    });
  }
  if (option != "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { Status: option.trim().toLowerCase() },
    });
  }
  if (searchParty.trim() !== "") {
    searchParameters.query.bool.should.push({
      regexp: {
        "Party.PartyName": "@" + searchParty.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.should.push({
      regexp: {
        "Party.PartyFirstName": "@" + searchParty.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.should.push({
      regexp: {
        "Party.PartyLastName": "@" + searchParty.trim().toLowerCase() + ".*",
      },
    });
  } else {
    searchParameters.query.bool.should.push({
      regexp: { "Party.PartyName": "@.*" },
    });
  }
  if (searchExpenseCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        ExpenseCategory:
          "@" + searchExpenseCategory.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchExpenseNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        ExpenseNo: "@" + searchExpenseNumber.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchReferenceNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        RefNo: "@" + searchReferenceNumber.trim().toLowerCase() + ".*",
      },
    });
  }
  console.log(JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      const expenses = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        expenses.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenses,
      };
    });
  };
}

export function saveExpenses(Expense, key) {
  return (dispatch, getState) => {
    Expense.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    Expense.ActionBy = getState().user.user.Name;
    Expense.ActionByUID = getState().user.user.UID;
    Expense.ActionByEmailID = getState().user.user.EmailID;
    console.log("key", key);
    if (!isNullOrUndefined(key) && key.trim() !== "") {
      Expense.Action = constants.AUDIT_TRAIL_EDIT;
      return database
        .collection("Expenses")
        .doc(key)
        .update({
          ...Expense,
        })
        .then(() => {
          return key;
        })
        .catch(function(error) {
          console.error("Error updating expenses: ", error);
        });
    } else {
      Expense.Action = constants.AUDIT_TRAIL_NEW;
      Expense.CreatedOn = firebase.firestore.FieldValue.serverTimestamp();
      return database
        .collection("Expenses")
        .add({
          ...Expense,
        })
        .then(function(docRef) {
          return docRef.id;
        })
        .catch(function(error) {
          console.error("Error adding expenses: ", error);
        });
    }
  };
}

export function getExpenseDetails(key) {
  return (dispatch) => {
    return database
      .collection("Expenses")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Expense found", doc.data());
          return doc.data();
        } else {
          console.log("No such Expense!");
        }
      });
  };
}

export function searchParty(RegistrationID, StoreID, searchText) {
  return (dispatch, getState) => {
    let searchParameters = {
      from: 0,
      size: 50,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: RegistrationID } },
            // { "match": { "StoreID": StoreID } },
            { match: { IsDeleted: "false" } },
          ],
          should: [
            {
              regexp: {
                PartyFirstName: "@" + searchText.trim().toLowerCase() + ".*",
              },
            },
            {
              regexp: {
                PartyLastName: "@" + searchText.trim().toLowerCase() + ".*",
              },
            },
            {
              regexp: {
                PartyCode: "@" + searchText.trim().toLowerCase() + ".*",
              },
            },
            {
              regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
            },
            {
              regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
            },
          ],
          minimum_should_match: 1,
        },
      },
    };
    console.log(JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getParties");
    return addMessage({ text: searchParameters }).then(function(result) {
      const parties = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let party = data._source;
        console.log("party", party);
        if (getState().user.user.Role === "Power User") {
          parties.push({ key: data._id, ...data._source });
        } else if (
          party.hasOwnProperty("StoreID") &&
          party.StoreID.trim() !== "" &&
          party.StoreID === getState().user.store.key
        ) {
          parties.push({ key: data._id, ...data._source });
        } else if (
          party.hasOwnProperty("AssociatedStores") &&
          party.AssociatedStores &&
          party.AssociatedStores.filter(
            (f) => f.StoreID === getState().user.store.key
          ).length > 0
        ) {
          parties.push({ key: data._id, ...data._source });
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: parties,
      };
    });
  };
}

export function cancelExpense(cancellationReason, key) {
  return (dispatch, getState) => {
    let actionOn = firebase.firestore.FieldValue.serverTimestamp();
    let actionBy = getState().user.user.Name;
    let actionByUID = getState().user.user.UID;
    let actionByEmailID = getState().user.user.EmailID;
    let action = "CANCEL";
    return database
      .collection("Expenses")
      .doc(key)
      .update({
        Status: "Cancelled",
        CancellationReason: cancellationReason,
        ActionOn: actionOn,
        ActionBy: actionBy,
        ActionByUID: actionByUID,
        ActionByEmailID: actionByEmailID,
        Action: action,
      });
  };
}

export function getCashFromCounterAmountForCashRegisterClosing(
  cashRegisterID,
  fromDate
) {
  return (dispatch, getState) => {
    return database
      .collection("Expenses")
      .where("CashRegisterID", "==", cashRegisterID)
      .where("Status", "==", "Paid")
      .where("ExpenseDateTime", "<=", new Date())
      .where("ExpenseDateTime", ">=", fromDate)
      .get()
      .then((querySnapshot) => {
        let amount = 0;
        querySnapshot.forEach((doc) => {
          amount = amount + Number(doc.data().Amount);
          console.log("amount", doc.data().Amount);
        });
        // console.log('paymentModeAmount',paymentModeAmount)
        return amount;
      })
      .catch((error) => {
        console.log(
          "Error getting getCashFromCounterAmountForCashRegisterClosing : ",
          error
        );
      });
  };
}

// export function getVendorPendingPayments(vendorID, storeID) {
//   // console.log("vendorID",vendorID);
//   // console.log("vstoreID",storeID);

//   return async (dispatch) => {
//     //get list of all transactions
//     let vendorTransactionsQuery = {
//       from: 0,
//       size: 10000,
//       track_total_hits: true,
//       query: {
//         bool: {
//           must: [
//             {
//               match: {
//                 "Store.key": storeID,
//               },
//             },
//             {
//               exists: {
//                 field: "Vendor.key",
//               },
//             },
//             {
//               match: {
//                 "Vendor.key": vendorID,
//               },
//             },
//           ],
//           filter: {
//             bool: {
//               must: [],
//             },
//           },
//         },
//       },
//       sort: [
//         {
//           TransactionDate: {
//             order: "desc",
//           },
//         },
//       ],
//     };
//     // console.log("vendorTransactionsQuery",JSON.stringify(vendorTransactionsQuery))
//     let addMessage = firebase.functions().httpsCallable("getInventory");
//     let vendorTransactions = await addMessage({
//       text: vendorTransactionsQuery,
//     }).then(function(result) {
//       const list = [];
//       let sanitizedMessage = result.data.text;
//       console.log("sanitizedMessage", sanitizedMessage);
//       sanitizedMessage.hits.hits.map((data, index) => {
//         let obj = data._source;
//         // console.log(
//         //   "obj.TransactionType.IncludePriceAndTax",
//         //   obj.TransactionType.IncludePriceAndTax
//         // );
//         if (obj.TransactionType.IncludePriceAndTax) {
//           // let PaidAmount = obj.hasOwnProperty("PaidAmount") ? obj.PaidAmount : 0;
//           // obj.BalanceAmountToBePaid = Number(obj.BillAmount) - PaidAmount;
//           // obj.PaidAmount = PaidAmount;
//           // if(obj.BalanceAmountToBePaid > 0){
//           let negativeAmount = false;
//           if (
//             obj.TransactionType.hasOwnProperty("UpdateStockInwardOrOutward") &&
//             obj.TransactionType.UpdateStockInwardOrOutward &&
//             obj.TransactionType.UpdateStockInwardOrOutward === "outward"
//           ) {
//             negativeAmount = true;
//           }
//           list.push({
//             key: data._id,
//             TransactionNo: obj.TransactionNo,
//             RefNo: obj.RefNo,
//             Notes: obj.Notes,
//             // TransactionDate: obj.TransactionDate,
//             TransactionDate: new Date(obj.TransactionDate)
//               .toString()
//               .substring(0, 15),
//             BillAmount: !negativeAmount ? obj.BillAmount : -1 * obj.BillAmount,
//             PaidAmount: 0, //obj.PaidAmount,
//             BalanceAmountToBePaid: !negativeAmount
//               ? obj.BillAmount
//               : -1 * obj.BillAmount, // obj.BalanceAmountToBePaid
//           });
//           // data._source, BalanceAmountToBePaid:BalanceAmountToBePaid
//           // }
//         }
//       });
//       return list;
//     });
//     // console.log("vendorTransactions",vendorTransactions);

//     let paymentQuery = {
//       from: 0,
//       size: 10000,
//       query: {
//         bool: {
//           must: [
//             {
//               match: {
//                 StoreID: storeID,
//               },
//             },
//             {
//               match: {
//                 "Party.PartyType": "Vendor",
//               },
//             },
//             {
//               match: {
//                 "Party.key": "vendor_" + vendorID,
//               },
//             },
//           ],
//           must_not: [
//             {
//               match: {
//                 Status: "Pending",
//               },
//             },
//           ],
//           filter: {
//             bool: {
//               must: [],
//             },
//           },
//         },
//       },
//       sort: [
//         {
//           ExpenseDateTime: {
//             order: "desc",
//           },
//         },
//       ],
//     };

//     console.log("paymentQuery", JSON.stringify(paymentQuery));
//     let payments = [];
//     let paymentMessage = firebase.functions().httpsCallable("getExpenses");
//     payments = await paymentMessage({ text: paymentQuery }).then(function(
//       result
//     ) {
//       const list = [];
//       let sanitizedMessage = result.data.text;
//       console.log("sanitizedMessage", sanitizedMessage);

//       sanitizedMessage.hits.hits.map((data, index) => {
//         let obj = data._source;
//         if (
//           obj.hasOwnProperty("Transactions") &&
//           !obj.hasOwnProperty("CancellationReason") &&
//           obj.Transactions.length > 0
//         ) {
//           obj.Transactions.map((m) => {
//             list.push(m);
//           });
//         }
//       });
//       // console.log("getVendorLedgerFromExpense Data", JSON.stringify(vendorLedgers));
//       return list;
//     });

//     // console.log("payments", payments);
//     for (let i = 0; i < vendorTransactions.length; i++) {
//       let paidAmount = 0;
//       if (
//         payments.filter((f) => f.key === vendorTransactions[i].key).length > 0
//       ) {
//         // console.log(
//         //   "payment found for vendor transaction",
//         //   vendorTransactions[i]
//         // );
//         payments
//           .filter((f) => f.key === vendorTransactions[i].key)
//           .map((p) => {
//             paidAmount = Number(paidAmount) + Number(p.BalanceAmountToBePaid);
//           });
//       }
//       vendorTransactions[i].PaidAmount = paidAmount;
//       vendorTransactions[i].BalanceAmountToBePaid =
//         Number(vendorTransactions[i].BillAmount) -
//         Number(vendorTransactions[i].PaidAmount);
//     }
//     return vendorTransactions.filter((f) => f.BalanceAmountToBePaid !== 0);
//   };
// }

export function getVendorPendingPayments(vendorID, storeIDs) {
  return async (dispatch) => {
    // Ensure storeIDs is an array
    if (!Array.isArray(storeIDs)) {
      storeIDs = [storeIDs];
    }

    // Query to get vendor transactions
    let vendorTransactionsQuery = {
      from: 0,
      size: 10000,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            // {
            //   terms: {
            //     "Store.key.keyword": storeIDs, // Accepts multiple storeIDs
            //   },
            // },
            {
              exists: {
                field: "Vendor.key",
              },
            },
            {
              match: {
                "Vendor.key": vendorID,
              },
            },
          ],
          must_not: [
            {
              match: {
                Status: "Cancelled",
              },
            },
          ],
          // filter: {
          //   bool: {
          //     must: [],
          //   },
          // },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };

    let addMessage = firebase.functions().httpsCallable("getInventory");
    let vendorTransactions = await addMessage({
      text: vendorTransactionsQuery,
    }).then((result) => {
      const list = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.hits.hits.forEach((data) => {
        let obj = data._source;
        if (obj.TransactionType.IncludePriceAndTax) {
          let negativeAmount = false;
          if (obj.TransactionType.UpdateStockInwardOrOutward === "outward") {
            negativeAmount = true;
          }
          list.push({
            key: data._id,
            TransactionNo: obj.TransactionNo,
            RefNo: obj.RefNo,
            Notes: obj.Notes,
            TransactionDate: new Date(obj.TransactionDate)
              .toString()
              .substring(0, 15),
            BillAmount: !negativeAmount ? obj.BillAmount : -1 * obj.BillAmount,
            PaidAmount: 0,
            BalanceAmountToBePaid: !negativeAmount
              ? obj.BillAmount
              : -1 * obj.BillAmount,
          });
        }
      });
      return list;
    });

    // Query to get payments
    let paymentQuery = {
      from: 0,
      size: 10000,
      query: {
        bool: {
          must: [
            // {
            //   terms: {
            //     "StoreID.key.keyword": storeIDs, // Accepts multiple storeIDs
            //   },
            // },
            {
              match: {
                "Party.PartyType": "Vendor",
              },
            },
            {
              match: {
                "Party.key": "vendor_" + vendorID,
              },
            },
          ],
          must_not: [
            {
              match: {
                Status: "Pending",
              },
            },
          ],
          // filter: {
          //   bool: {
          //     must: [],
          //   },
          // },
        },
      },
      sort: [
        {
          ExpenseDateTime: {
            order: "desc",
          },
        },
      ],
    };

    let payments = [];
    let paymentMessage = firebase.functions().httpsCallable("getExpenses");
    payments = await paymentMessage({ text: paymentQuery }).then((result) => {
      const list = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.hits.hits.forEach((data) => {
        let obj = data._source;
        if (
          obj.hasOwnProperty("Transactions") &&
          !obj.hasOwnProperty("CancellationReason") &&
          obj.Transactions.length > 0
        ) {
          obj.Transactions.forEach((m) => {
            list.push(m);
          });
        }
      });
      return list;
    });

    // Process transactions with payments
    for (let i = 0; i < vendorTransactions.length; i++) {
      let paidAmount = 0;
      let paymentMatches = payments.filter(
        (p) => p.key === vendorTransactions[i].key
      );

      if (paymentMatches.length > 0) {
        paymentMatches.forEach((p) => {
          paidAmount += Number(p.BalanceAmountToBePaid);
        });
      }

      vendorTransactions[i].PaidAmount = paidAmount;
      vendorTransactions[i].BalanceAmountToBePaid =
        Number(vendorTransactions[i].BillAmount) -
        Number(vendorTransactions[i].PaidAmount);
    }

    return vendorTransactions.filter((f) => f.BalanceAmountToBePaid !== 0);
  };
}
