import React, { Component } from "react";
import Window from "../2/Controls/Window";
import Input from "../2/Controls/Input";
import Spinner from "../Common/Spinner";
import "./GCLookUp.css";
import { searchPincodeGlobalCatalog } from "../../services/api/PincodeAPI";
class GCLookup extends Component {
  constructor() {
    super();
    this.state = {
      selectedOption: "1",
      searchText: "",
      products: [],
    };
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    let searchText = this.props.GCLookupText ? this.props.GCLookupText : "";
    let selectedOption = this.props.GCLookupType
      ? this.props.GCLookupType
      : "1";
    this.setState({
      searchText: searchText,
      selectedOption: selectedOption,
    });
    this.search(searchText, selectedOption);
  }

  async search(searchText, selectedOption) {
    this.setState({ loading: true });
    let result = await searchPincodeGlobalCatalog(
      "",
      "",
      selectedOption === "1" ? "open" : "barcode",
      searchText
    );
    // console.log("result", JSON.stringify(result));
    let products = [];
    if (result && result.length > 0) {
      result.map((m) => {
        m.DisplayName = m.Name + " " + m.Quantity + " " + m.UOM;
        products.push(m);
      });
    }
    // console.log("products", products);

    this.setState({ products: products, loading: false });
  }

  render() {
    return (
      <Window
        header={"Catalogue Search"}
        modal={true}
        width={"600px"}
        height={"600px"}
        onClose={() => this.props.onClose()}
        onButtonClick={(buttonType) => {}}
        buttons={[]}
      >
        <div className="row">
          <div className="col-md-12">
            <Input
              type="text"
              placeholder="Search by product name or code"
              value={this.state.searchText}
              onChange={(value) => this.setState({ searchText: value })}
            />

            <label>
              <input
                type="radio"
                value="1"
                checked={this.state.selectedOption === "1"}
                onChange={() => this.setState({ selectedOption: "1" })}
              />{" "}
              Search for non-branded items such as rice, wheat, eggs, tomatoes,
              etc., select this option.
            </label>
            <br />
            <label>
              <input
                type="radio"
                value="2"
                checked={this.state.selectedOption === "2"}
                onChange={() => this.setState({ selectedOption: "2" })}
              />{" "}
              Search for standard FMCG items with EAN codes, such as packaged
              goods and branded products.
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-primary pull-right"
              onClick={() =>
                this.search(this.state.searchText, this.state.selectedOption)
              }
            >
              Search
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12" style={{ height: "400px" }}>
            {this.state.loading && <Spinner />}
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  {/* <th>Barcode</th> */}
                  <th>Product Details</th>
                  <th>MRP</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map((m, index) => (
                  <tr key={"tdtr" + index}>
                    <td>
                      <img src={m.Image} className="list-page-image" />
                    </td>
                    <td>
                      <div>{m.DisplayName}</div>
                      <div>{m.Code}</div>
                      <div>{m.PincodeProductID}</div>
                    </td>
                    <td>{m.MRP}</td>
                    <td>
                      <button
                        className="btn btn-primary pull-right"
                        onClick={() => this.props.onSelect(m)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Window>
    );
  }
}
export default GCLookup;
